<template>
  <div>
    <b-card border-variant="danger" v-if="errore_caricamento">
      <b-row class="match-height">
        <b-col
          md="8"
        >
          <h4 class="mt-0 mb-0 text-danger">Errore durante il caricamento delle informazioni iniziali</h4>
          <p>Ti preghiamo di segnalare il problema all'assistenza tecnica, grazie</p>
        </b-col>
        <b-col 
          md="4"
          class="text-center"
        >
          <b-button
          variant="primary"
          :to="{ name: 'crm-customer-index' }"
          >
              <feather-icon
                  icon="CornerUpLeftIcon"
                  size="16"
                  class="mr-1"
              />Torna ai Clienti
          </b-button>
        </b-col>
      </b-row>
        
    </b-card>
    
    <div class="text-center my-3" v-if="Caricato == false">
        <b-spinner
            variant="primary"
            style="width: 3rem; height: 3rem;"
            class="mr-1"
            label="loading"
        />
    </div>

    <div v-if="Caricato">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-tabs pills>
            <b-tab active>
              <template #title>
                <feather-icon
                  icon="UserIcon"
                  size="16"
                  class="mr-0 mr-sm-50"
                />
                <span class="d-none d-sm-inline">Dettagli</span>
              </template>
              <b-card bg-variant="Default">
                <b-row>
                  <b-col md="4" lg="3">
                    <b-form-group label="Ragione Sociale *" label-for="company_name">
                        <validation-provider
                        #default="{ errors }"
                        name="Ragione Sociale"
                        rules="required"
                        >
                        <b-form-input
                          v-model="campiform.billing.company_name"
                          placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="4" lg="3">
                    <b-form-group label="Nome *" label-for="name">
                        <validation-provider
                        #default="{ errors }"
                        name="Nome"
                        rules="required"
                        >
                        <b-form-input
                            v-model="campiform.user.name"
                            placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="4" lg="3">
                    <b-form-group label="Cognome *" label-for="Surname">
                        <validation-provider
                        #default="{ errors }"
                        name="Cognome"
                        rules="required"
                        >
                        <b-form-input
                            v-model="campiform.user.surname"
                            :state="errors.length > 0 ? false:null"
                            placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col md="4" lg="6">
                    <b-form-group label="Email *" label-for="email">
                        <validation-provider
                        #default="{ errors }"
                        name="Email dell'utente"
                        rules="required|email"
                        >
                        <b-form-input
                            v-model="campiform.user.email"
                            :state="errors.length > 0 ? false:null"
                            placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="4" lg="3">
                    <b-form-group label="Telefono" label-for="telefono">
                        <b-form-input
                            v-model="campiform.user.phone"
                            placeholder=""
                        />
                    </b-form-group>
                  </b-col>
                  <b-col md="4" lg="3">
                    <b-form-group label="Cellulare *" label-for="Cellulare">
                        <validation-provider
                        #default="{ errors }"
                        name="Cellulare"
                        rules="required"
                        >
                        <b-form-input
                            v-model="campiform.user.mobile_phone"
                            placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="4" lg="3">
                    <b-form-group label="Reseller *" label-for="reseller_id">
                      <b-form-select
                        v-model="campiform.customer.reseller_id"
                        :options="options_usercrm_reseller"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4" lg="3">
                    <b-form-group label="Assistente *" label-for="assistant_id">
                      <b-form-select
                        v-model="campiform.customer.assistant_id"
                        :options="options_usercrm_assistente"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4" lg="3">
                    <b-form-group label="Valutazione *" label-for="evaluation_id">
                      <validation-provider
                      #default="{ errors }"
                      name="Valutazione"
                      rules="required"
                      >
                      <b-form-select
                        v-model="campiform.customer.evaluation_id"
                        :options="options_evalution"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="4" lg="3">
                    <b-form-group label="Rating" label-for="rating">
                        <b-form-select
                            v-model="campiform.customer.rating"
                            :options="options_rating"
                        />
                    </b-form-group>
                  </b-col>
                  <b-col md="4" lg="6">
                    <b-form-group label="Sito web" label-for="website">
                        <b-form-input
                            v-model="campiform.user.website"
                            placeholder=""
                        />
                    </b-form-group>
                  </b-col>
                </b-row>

              </b-card>
            </b-tab>

            <b-tab>
              <template #title>
                <feather-icon
                  icon="LockIcon"
                  size="16"
                  class="mr-0 mr-sm-50"
                />
                <span class="d-none d-sm-inline">Fatturazione</span>
              </template>
              <b-card bg-variant="Default">
                <b-row>
                  <b-col md="4" lg="3">
                    <b-form-group label="Nazione *" label-for="country_id">
                      <validation-provider
                      #default="{ errors }"
                      name="Nazione"
                      rules="required"
                      >
                        <vue-autosuggest
                          ref="autocomplete"
                          v-model="valoreDefaultNazione"
                          :suggestions="filteredOptions"
                          :limit="8"
                          :input-props="{id:'autosuggest__input',class:'form-control', placeholder:'cerca nazione', }"
                          :render-suggestion="renderSuggestion"
                          :get-suggestion-value="getSuggestionValue"
                          @input="onInputChange"
                          @selected="selectHandler"
                        >
                          <template slot-scope="{suggestion}">
                            <span class="my-suggestion-item">{{ suggestion.item.name }}</span>
                          </template>
                        </vue-autosuggest>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="4" lg="3">
                    <b-form-group label="Indirizzo *" label-for="address">
                      <validation-provider
                      #default="{ errors }"
                      name="Indirizzo"
                      rules="required|min:2"
                      >
                        <b-form-input
                            v-model="campiform.billing.address"
                            placeholder=""
                            :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="4" lg="3">
                    <b-form-group label="Cap *" label-for="zip">
                      <validation-provider
                      #default="{ errors }"
                      name="Cap"
                      rules="required|digits:5"
                      >
                      <b-form-input
                          v-model="campiform.billing.zip"
                          :state="errors.length > 0 ? false:null"
                          placeholder=""
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="4" lg="3">
                    <b-form-group label="Città *" label-for="city">
                      <validation-provider
                      #default="{ errors }"
                      name="Città"
                      rules="required|min:2"
                      >
                        <b-form-input
                            v-model="campiform.billing.city"
                            placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="4" lg="3">
                    <b-form-group label="Provincia *" label-for="province_id">
                      <validation-provider
                      #default="{ errors }"
                      name="Partita IVA"
                      rules="required"
                      >
                        <b-form-select
                          v-model="campiform.billing.province_id"
                          :options="options_province"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="4" lg="3">
                    <b-form-group label="Partita IVA *" label-for="vat_number">
                      <validation-provider
                      #default="{ errors }"
                      name="Partita IVA"
                      rules="required|digits:11"
                      >
                        <b-form-input
                          v-model="campiform.billing.vat_number"
                          :state="errors.length > 0 ? false:null"
                          placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="4" lg="3">
                    <b-form-group label="Codice fiscale" label-for="fiscal_code">
                      <b-form-input
                        v-model="campiform.billing.fiscal_code"
                        placeholder=""
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4" lg="3">
                    <b-form-group label="Pec" label-for="pec">
                      <b-form-input
                        v-model="campiform.billing.pec"
                        placeholder=""
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4" lg="3">
                    <b-form-group label="Codice SDI" label-for="dest_code">
                      <validation-provider
                      #default="{ errors }"
                      name="Codice SDI"
                      rules="length:7"
                      >
                        <b-form-input
                          v-model="campiform.billing.dest_code"
                          :state="errors.length > 0 ? false:null"
                          placeholder=""
                        />
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="4" lg="3">
                    <b-form-group label="Regime IVA" label-for="vat_scheme_id">
                      <b-form-select
                        v-model="campiform.billing.vat_scheme_id"
                        :options="options_regine_iva"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4" lg="6">
                    <b-form-group
                      label="Pagamenti"
                      label-for="v-adse-pagamenti"
                    >
                      <multiselect
                        v-model="campiform.payment.list"
                        :options="options_payment"
                        :custom-label="multiSelect_act_Lingua" 
                        placeholder="seleziona o ricerca"
                        :multiple="true"
                        :close-on-select="false" 
                        :clear-on-select="false"
                        :preserve-search="true"
                        :show-labels="false"
                        :max-height="250"
                        :max="5"
                        label="pagamenti" 
                        track-by="text" 
                      >
                        <span slot="noResult">Nessun risultato</span>
                        <span slot="maxElements">Limite opzioni selezionabili raggiunto</span>
                      </multiselect>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
            </b-tab>
          </b-tabs>

          <b-row>
              <b-col md="12" class="text-center">
                <b-button
                  variant="primary"
                  type="submit"
                  :disabled="action_press"
                  @click.prevent="validationForm()"
                >
                  <div v-if="action_press">Salvaggio in corso <b-spinner small class="ml-1" /></div>
                  <div v-else>Salva</div> 
                </b-button>
              </b-col>
          </b-row>
          
        </b-form>
      </validation-observer>        
    </div>      
  </div>
</template>

<script>
import router from '@/router'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import it from 'vee-validate/dist/locale/it.json'
import { BTabs, BTab, BRow, BCol, BCard, BCardText, BSpinner, BButton, BFormGroup, BForm, BInputGroup, BFormInput, BFormTextarea, BInputGroupPrepend, BInputGroupAppend, BDropdownDivider, BFormSelect } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  required, email, digits, length, min
} from '@validations'
import { VueAutosuggest } from 'vue-autosuggest'
import Multiselect from 'vue-multiselect' 

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    it,
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard, 
    BCardText, 
    BSpinner, 
    BButton, 
    BForm,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BFormTextarea, 
    BInputGroupPrepend,
    BInputGroupAppend,
    BDropdownDivider,
    BFormSelect,

    VueAutosuggest, 
    Multiselect, 

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent, 
  },
  data() {
    return {
      id_italina: '114',
      valoreDefaultNazione: "",
      campiform: {
        customer: {
          id: '',
          user_id: '',
          reseller_id: '',
          assistant_id: '',
          hr_id: '',
          rating: '',
          created_at: '',
          source: {
            value: '',
            text: '',
          },
          lead_registered_at: '',
          converted_at: ''
        },
        user: {
          email: '', 
          name: '', 
          surname: '',
          mobile_phone: '',
          phone: '',
          website: '',
        },
        billing: {
          country_id: '',
          province_id: '',
          address: '',
          zip: '',
          city: '',
          company_name: '',
          vat_number: '',
          fiscal_code: '',
          pec: '',
          dest_code: '',
          vat_scheme_id: null,
        },
        payment: { 
          list : [ ],
        }
      },
      required,
      email,
      digits,
      length,
      min,
      
      options_rating: [
        { value: 1, text: '1' },
        { value: 2, text: '2' },
        { value: 3, text: '3' },
        { value: 4, text: '4' },
        { value: 5, text: '5' },
      ],
      options_usercrm_reseller: [ ],
      options_usercrm_assistente: [ ],
      options_evalution: [ ],
      options_source: [ ], 
      options_payment: [ ], 
      options: [ ],
      options_province: [ ],
      options_regine_iva: [ ],
      Caricato: false,
      errore_caricamento: false,
      action_press: false,

      filteredOptions: [],
      limit: 10,
    }
  },
  created() {
    //errori validazione in italiano
    localize({
      it: {
        messages: it.messages,
      },
    })

    const countryPromise = this.$http.get('api/fs/country/list')
    const provincePromise = this.$http.get('api/fs/province/list')
    const userCrmAssistentiPromise = this.$http.get('api/crm/user/list?columnFilters={"role.type":"employee", "role.name":"assistant" }&page=1&perPage=100&status=active')
    const userCrmResellerPromise = this.$http.get('api/crm/user/list?columnFilters={"role.type":"employee", "role.name":"reseller" }&page=1&perPage=100&status=active')
    const evaluationPromise = this.$http.get('api/fs/lead/evaluation/list')
    const vatSchemePromise = this.$http.get('api/fs/employee/vat-scheme/list')
    const infoCustomerPromise = this.$http.get('api/crm/customer/card/'+router.currentRoute.params.id_riga)
    const infoBillingPromise = this.$http.get('api/crm/billing/customer/card/'+router.currentRoute.params.id_riga)
    const sourcePromise = this.$http.get('api/fs/lead/source/list')
    const paymentPromise = this.$http.get('api/fs/customer/payment/list')

    Promise.all([countryPromise, provincePromise, userCrmAssistentiPromise, userCrmResellerPromise, evaluationPromise, vatSchemePromise, infoCustomerPromise, infoBillingPromise, sourcePromise, paymentPromise]).then(response => {
      if(response[0].data.statusCode===200){
        //caricamento lista nazioni
        this.options = response[0].data.reply

        if(response[1].data.statusCode===200){
          //caricamento lista province italiane
          this.options_province = response[1].data.reply

          if(response[2].data.statusCode===200){
            //caricamento lista "assistent"
            let i = 0;
            while (i < response[2].data.reply.meta.total) {
              this.options_usercrm_assistente[i] = { value: response[2].data.reply.data[i].id, text: response[2].data.reply.data[i].name+' '+response[2].data.reply.data[i].surname },
              
              i++;
            }

            if(response[3].data.statusCode===200){
              //caricamento lista "reseller"
              let i = 0;
              while (i < response[3].data.reply.meta.total) {
                this.options_usercrm_reseller[i] = { value: response[3].data.reply.data[i].id, text: response[3].data.reply.data[i].name+' '+response[3].data.reply.data[i].surname },
                
                i++;
              }

              if(response[4].data.statusCode===200){
                //caricamento lista evalutation
                this.options_evalution = response[4].data.reply

                if(response[5].data.statusCode===200){
                  //caricamento lista regime iva
                  this.options_regine_iva = response[5].data.reply  
                  
                  if(response[6].data.statusCode===200){
                      //dati customer
                      this.campiform.customer = response[6].data.reply.customerData

                      //caricamento dati billing
                      this.campiform.billing = response[7].data.reply.billingData

                      //valore default per campo Nazione con autosuggest
                      var id_nazione = this.campiform.billing.country_id
                      var nazione = this.options.filter(function(elem){
                          if(elem.value == id_nazione){
                          return elem.text
                          }
                      })
                      this.valoreDefaultNazione = nazione[0].text

                      //caricamento dati source lead
                      this.options_source = response[8].data.reply

                      this.campiform.payment.list = response[6].data.reply.customerData.payment.map(item => {
                        return {
                          value: item.id,
                          text: item.name
                        };
                      });

                      if(response[9].data.statusCode===200){
                        this.options_payment = response[9].data.reply

                          //estrazione dati User (sicrono)
                          this.$http.get('api/crm/user/customer/card/'+this.campiform.customer.id).then(response_user => {
                            if((response_user.data.statusCode>=200) && (response_user.data.statusCode<=299)){
                              //risposta positiva
                              this.campiform.user = response_user.data.reply.userData
                              
                              this.Caricato = true;
                              this.errore_caricamento = false;
                            } else {
                              //risposta negativa (errore sul server)
                              
                              this.Caricato = false;
                              this.errore_caricamento = true;
                            }
                          }).catch(e => {
                            console.log(e);
                          }); 

                      } else {
                        this.Caricato = false;
                        this.errore_caricamento = true;
                      }
                  } else {
                  this.Caricato = false;
                  this.errore_caricamento = true;
                  }
                } else {
                this.Caricato = false;
                this.errore_caricamento = true;
                }
              } else {
              this.Caricato = false;
              this.errore_caricamento = true;
              }
              
            } else {
              this.Caricato = false;
              this.errore_caricamento = true;
            }
          } else {
            this.Caricato = false;
            this.errore_caricamento = true;
          }
        } else {
          this.Caricato = false;
          this.errore_caricamento = true;
        }
      } else {
        this.Caricato = false;
        this.errore_caricamento = true;
      }
    })
    
  },
  methods: {
    onInputChange(testo_ricerca) {
      if (testo_ricerca === '' || testo_ricerca === undefined) {
        return
      }

      /* filtra array nazioni - con filtro ricerca "stringa in qualsiasi posizizione" */
      //const filteredData = this.options.filter(item => item.text.toLowerCase().indexOf(testo_ricerca.toLowerCase()) > -1).slice(0, this.limit)
      
      /* filtra array nazioni - con filtro ricerca "stringa in posizizione iniziale" */
      const filteredData = this.options.filter(item => item.text.toLowerCase().indexOf(testo_ricerca.toLowerCase()) == 0).slice(0, this.limit)
      
      //console.log("filteredData ----------- per -> "+testo_ricerca)
      //console.log(filteredData)
      
      this.filteredOptions = [{
        data: filteredData,
      }]
    },
    renderSuggestion(suggestion) {
      //per gestire le voci restituite nella finestra auto-suggest
      //console.log("renderSuggestion")
      //console.dir(suggestion)
      return suggestion.item.text;
    },
    getSuggestionValue(suggestion) {
      //per gestire il testo che verra visualizzato dopo la selezione dela voce dalla finestra dell'auto-suggest
      //console.log("getSuggestionValue")
      //console.dir(suggestion)

      return suggestion.item.text;
    },
    selectHandler(text){
      if (text === '' || text === undefined) {
        return
      } else {
        //associo id_nazione al parametro da passare all'api
        this.campiform.billing.country_id = text.item.value
        //console.log(this.campiform.billing)

        if(this.campiform.billing.country_id == this.id_italina){
          //forzo campo "province_id"
          this.campiform.billing.province_id = '0';
        } else {
          //province_id estera
          this.campiform.billing.province_id = '112';
        }
      }
    },
    multiSelect_act_Lingua ({ value, text }) {
      return `${text}`
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          //console.log(this.campiform)
          this.action_press = true

          this.$http.post('api/crm/customer/card/'+this.campiform.customer.id, 
            this.campiform.customer 
            ).then(response => { 
              if((response.data.statusCode>=200) && (response.data.statusCode<=299)){
                //risposta positiva
                if(response.data.reply.customerData.id > 0){             
                  //OK -> procedo al salvataggio dati "billing"
                  this.$http.post('api/crm/billing/customer/card/'+response.data.reply.customerData.id , 
                  this.campiform.billing
                  ).then(response_billing => {
                    if((response_billing.data.statusCode>=200) && (response_billing.data.statusCode<=299)){
                      //risposta positiva
                      if(response_billing.data.reply.billingData.company_name != ''){             
                        //OK -> procedo al salvataggio dati "user"
                        this.$http.post('api/crm/user/customer/card/'+this.campiform.customer.id , 
                        this.campiform.user
                        ).then(response_user => {
                          if((response_user.data.statusCode>=200) && (response_user.data.statusCode<=299)){
                            //risposta positiva
                            //OK -> procedo alla sincronizzazione dei dati dell'anagrafica appena modificata su FIC

                            this.$http.post('api/crm/customer/sync_fic/'+this.campiform.customer.id)
                            .then(response_sync => {
                              if((response_sync.data.statusCode>=200) && (response_sync.data.statusCode<=299)){
                                //risposta positiva
                                
                                //ok -> procedo al salvataggio dei valori del Payment
                                this.$http.post('api/crm/customer/savepaymentsedit/'+this.campiform.customer.id, 
                                    this.campiform.payment
                                  ).then(response_payment => {
                                    if((response_payment.data.statusCode>=200) && (response_payment.data.statusCode<=299)){
                                      //risposta positiva
                                      this.$router.replace('/crm/customers')
                                        .then(() => {
                                          this.$swal({
                                              icon: 'success',
                                              title: 'Cliente aggiornato correttamente',
                                              confirmButtonText: 'chiudi',
                                              customClass: {
                                              confirmButton: 'btn btn-success',
                                              },
                                          })
                                        })
                                    
                                    } else {
                                      //risposta negativa (errore sul server)
                                      this.$swal({
                                          icon: 'error',
                                          title: 'Si è verificato un errore in Lead_Payments',
                                          text: 'Errore: A03 - '+response_payment.data.reply.message,
                                          customClass: {
                                          confirmButton: 'btn btn-outline-primary',
                                          },
                                      })

                                      this.action_press = false
                                    }
                                  }).catch(e => {
                                    console.log(e);
                                  });
                              
                              } else {
                                //risposta negativa (errore sul server)
                                this.$swal({
                                    icon: 'error',
                                    title: 'Si è verificato un errore nella sincronizzazione dai dati su Fatture in Cloud',
                                    text: 'operazione non riuscita, ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema',
                                    customClass: {
                                    confirmButton: 'btn btn-outline-primary',
                                    },
                                })
                                  
                                this.action_press = false
                              }
                            }).catch(e => {
                              console.log(e);
                            });
                          
                          } else {
                            //risposta negativa (errore sul server)
                            this.$swal({
                                icon: 'error',
                                title: 'Si è verificato un errore nell\'aggiornamento utente',
                                text: 'operazione non riuscita, ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema',
                                customClass: {
                                confirmButton: 'btn btn-outline-primary',
                                },
                            })
                              
                            this.action_press = false
                          }
                        }).catch(e => {
                          console.log(e);
                        });
                      } else {
                        //questo non dovrebbe mai accadere ... ma non si sa mai -> visualizzo risposta ma non faccio redirect
                        this.$swal({
                            icon: 'warning',
                            title: 'Si è verificato un errore sul Cliente',
                            text: 'Salvataggio riuscito ma con errore '+response.data.statusCode+', ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema',
                            customClass: {
                            confirmButton: 'btn btn-outline-primary',
                            },
                        })
                              
                        this.action_press = false
                      }

                    
                    } else {
                      //risposta negativa (errore sul server)
                      this.$swal({
                          icon: 'error',
                          title: 'Si è verificato un errore in Billing',
                          text: 'operazione non riuscita, ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema',
                          customClass: {
                          confirmButton: 'btn btn-outline-primary',
                          },
                      })
                        
                      this.action_press = false
                    }
                  }).catch(e => {
                    console.log(e);
                  });
                } else {
                  //questo non dovrebbe mai accadere ... ma non si sa mai -> visualizzo risposta ma non faccio redirect
                  this.$swal({
                      icon: 'warning',
                      title: 'Si è verificato un errore sul Cliente',
                      text: 'Salvataggio riuscito ma con errore '+response.data.statusCode+', ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema',
                      customClass: {
                      confirmButton: 'btn btn-outline-primary',
                      },
                  })
                        
                  this.action_press = false
                }
                
              } else {
                //risposta negativa (errore sul server)
                if(response.data.reply.message != ''){
                  this.$swal({
                      icon: 'warning',
                      title: 'Si è verificato un errore',
                      text: response.data.reply.message,
                      customClass: {
                      confirmButton: 'btn btn-outline-primary',
                      },
                  })
                } else {
                  this.$swal({
                      icon: 'error',
                      title: 'Si è verificato un errore generico',
                      text: 'operazione non riuscita, ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema',
                      customClass: {
                      confirmButton: 'btn btn-outline-primary',
                      },
                  })
                }

                this.action_press = false
              }
            }).catch(e => {
              console.log(e);
            });
          
        }
      })
    },

  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-sweetalert.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';
@import '@core/scss/vue/libs/vue-multiselect.min.scss';
</style>
